import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import sha1 from 'crypto-js/sha1';
import Hex from 'crypto-js/enc-hex'
import WordArray from 'crypto-js/lib-typedarrays'

@Injectable()
export class DocumentService {

    constructor(private _httpClient: HttpClient) {

     }

    //https://melvingeorge.me/blog/do-multiple-fetch-requests-parallel-javascript
    getDocument(token, documentId) {
        const fetchedUrl = environment.apiRoot + documentId + '&fragmentnumber=1';
        console.log("Calling api:", fetchedUrl);
        this.showloader();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/zip");
        myHeaders.append("Authorization", token);

        const requestOptions = {
            resource: '/',
            path: '/',
            headers: myHeaders,
            method: 'GET',
        };
        let filename;

        fetch(fetchedUrl, requestOptions)
            .then(response1 => {

                if(this.treatStatusCode(response1))
                {
                    const headerFragmentCount = new Number(response1.headers.get('fragmentcount'));
                    const headerLdapRole = response1.headers.get('ldaprole');
                    const headerContentDisposition = response1.headers.get('Content-Disposition');
                    const parts = headerContentDisposition.split(';');
                    const fragmentsize = new Number(response1.headers.get('fragmentsize'));
                    const zipsize = new Number(response1.headers.get('zipsize'));
                    filename = headerLdapRole.concat("_").concat(parts[1].split('=')[1].replace(/['"]/g, ''));

                    const arrayBuffer1 = response1.arrayBuffer();

                    console.log("header:fragmentcount=" + headerFragmentCount)
                    // if(headerFragmentCount > 1) {
                    //     const div = document.createElement('div');
                    //     const span = document.createElement('span');
                    //     const ul = document.createElement('ul');
                    //     const li = document.createElement('li');
                    //     span.innerHTML='<b>Note:</b> ZIP archive is big (' + (Number(zipsize) / 1024 / 1024).toFixed(1)+ ' MB)'
                    //     div.style.color='gray'
                    //     div.id='big_zip_msg'
                    //     li.innerText='Loading may take a little bit longer.'
                    //     ul.appendChild(li);
                    //     div.appendChild(span);
                    //     div.appendChild(ul);
                    //     document.body.appendChild(div);
                    // }
                    console.log("header:ldaprole=" + headerLdapRole)
                    const sha1sumExpected = response1.headers.get('sha1sum');
                    console.log("header:sha1sum=" + sha1sumExpected)
                    console.log("header:fragmentsize=" + fragmentsize)
                    console.log("header:zipsize=" + zipsize)
                    var fetchRequestsWithoutFragment1 = [];

                    for(var i=2; i <= headerFragmentCount; i++) {
                    const fetchedUrlX = environment.apiRoot + documentId + '&fragmentnumber=' + i;
                    console.log("Calling api:", fetchedUrlX);
                    const fetchReqX = fetch(fetchedUrlX, requestOptions).then((res) => res.arrayBuffer());
                    fetchRequestsWithoutFragment1.push(fetchReqX);
                }

                 function hideloader() {
                    document.getElementById('loading')
                        .style.display = 'none';
                }
                 function _arrayBufferToBase64( buffer ) {
                    var binary = '';
                    var bytes = new Uint8Array( buffer );
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    return window.btoa( binary );
                }
                 function concatenateArrayBuffers(array_of_arraybuffers) {
                     if(array_of_arraybuffers.length == 1) {
                         //nothing to concatenate
                         return array_of_arraybuffers[0]
                     }
                     var expected_FinalArrayBuffer_ByteLength = 0;

                     for(i=0; i< array_of_arraybuffers.length;i ++) {
                         var arraybuffer_element = array_of_arraybuffers[i]
                         var array_of_arraybuffer_element_length=arraybuffer_element.byteLength;
                         expected_FinalArrayBuffer_ByteLength = expected_FinalArrayBuffer_ByteLength + array_of_arraybuffer_element_length
                     }
                     let finalArrayBuffer = new ArrayBuffer(expected_FinalArrayBuffer_ByteLength);
                     let finalUint8Array = new Uint8Array(finalArrayBuffer);

                     var finalUint8ArrayIndex = 0

                     for(i=0; i< array_of_arraybuffers.length;i++) {
                         var arraybuffer_element = array_of_arraybuffers[i]
                         let arraybuffer_element_view = new Uint8Array(arraybuffer_element);

                         var array_of_arraybuffer_element_length=arraybuffer_element.byteLength;

                         for(var j = 0; j< array_of_arraybuffer_element_length; j++) {

                             finalUint8Array[finalUint8ArrayIndex] =arraybuffer_element_view[j];
                             ++finalUint8ArrayIndex
                         }

                     }
                     return finalArrayBuffer

                    }

                function base64ToArrayBuffer(base64String) {
                    var binary_string = window.atob(base64String);
                    var len = binary_string.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                        bytes[i] = binary_string.charCodeAt(i);
                    }
                    return bytes.buffer;
                }
                function mergeBase64EncodedArrayBuffersTogether(arrayBuffersEncodedAsBase64) {
                        var array =[];
                        for(var i = 0; i < arrayBuffersEncodedAsBase64.length; i++) {
                            var e = arrayBuffersEncodedAsBase64[i];
                        var arrayBufferE =  base64ToArrayBuffer(e);
                            array.push(arrayBufferE);
                        }
                        return concatenateArrayBuffers(array);
                }

                    Promise.all(fetchRequestsWithoutFragment1).then((arrayBuffersWithoutFirst) => {

                        arrayBuffer1.then(arrayBuffer1Result =>
                        {
                            var arrayBuffersAllEncodedAsBase64 = [];
                            arrayBuffersAllEncodedAsBase64.push(_arrayBufferToBase64(arrayBuffer1Result));

                            for(var i =0; i < arrayBuffersWithoutFirst.length; i++) {
                                var arrayBufferX = arrayBuffersWithoutFirst[i]
                                arrayBuffersAllEncodedAsBase64.push(_arrayBufferToBase64(arrayBufferX));
                            }

                            // if(headerFragmentCount > 1) {
                            //     document.getElementById('big_zip_msg').remove()
                            // }

                            console.log("ZIP file was loaded and will be downloaded.")


                            const finalArrayBufferForUserToBeDownloaded = mergeBase64EncodedArrayBuffersTogether(arrayBuffersAllEncodedAsBase64);
                            const finalArrayBufferForUserToBeDownloadedAsWordArray = WordArray.create(finalArrayBufferForUserToBeDownloaded);

                            const sha1sumReal = sha1(finalArrayBufferForUserToBeDownloadedAsWordArray).toString(Hex)

                            hideloader();
                            if(sha1sumExpected !== sha1sumReal) {
                                var msg = "Internal server error: SHA1 sum of received ZIP file is wrong: sha1sumExpected=" + sha1sumExpected + " sha1sumReal=" + sha1sumReal;
                                alert(msg)
                                throw new TypeError(msg)
                            }
                            this.downloadFile(finalArrayBufferForUserToBeDownloaded, filename)
                        }
                        );
                    });




                }
            })
            .catch(error => console.log('error', error));

    }

    downloadFile(base64EncodedBlob, filename) {
        const blob = new Blob([base64EncodedBlob], {type: 'octet/stream'});
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    treatStatusCode(response) {
        if(response.status /100 == 2) {
            console.log("Api call returned successfully.");
            return true;
        }
        else {
            response.text().then(result => {
                alert(result);
                console.log(result);
            });
            return false;
        }
    }

    showloader() {
        document.getElementById('loading')
            .style.display = 'block';
    }

    hideloader() {
        document.getElementById('loading')
            .style.display = 'none';
    }

}
