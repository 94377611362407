import { Injectable } from '@angular/core';
import { UserManager, User } from 'oidc-client';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  private _userManager: UserManager;
  private _user: User;
  private _loginChangedSubject = new Subject<boolean>();

  loginChanged = this._loginChangedSubject.asObservable();
  constructor(private _httpClient: HttpClient) {
    const stsSettings = {
      authority: environment.stsAuthority,
      client_id: environment.clientId,
      redirect_uri: `${environment.clientRoot}/signin-callback`,
      scope: 'openid arca vectury-user role-rnet-dgz-irn73292',
      acr_values: 'secure/name/x509/uri/RNETandTWIN',
      response_type: 'code',
      post_logout_redirect_uri: `${environment.clientRoot}/signout-callback`,
      automaticSilentRenew: false,
      silent_redirect_uri: `${environment.clientRoot}assets/silent-callback.html`,
      metadata: {
         issuer: `${environment.stsAuthority}`,
         authorization_endpoint: `${environment.stsAuthority}/authz`,
         jwks_uri: `${environment.stsAuthority}/keys`,
         token_endpoint: `${environment.stsAuthority}/token`,
         userinfo_endpoint: `${environment.stsAuthority}/userinfo`,
      //   end_session_endpoint: `${environment.stsAuthority}v2/logout?client_id=${environment.clientId}&returnTo=${encodeURI(environment.clientRoot)}signout-callback`
      }
    };
    this._userManager = new UserManager(stsSettings);
    this._userManager.events.addAccessTokenExpired(_ => {
      this._loginChangedSubject.next(false);
    });
    this._userManager.events.addUserLoaded(user => {
      console.log("User added:", user.profile["uid"], user.profile["firstname"], user.profile["lastname"]);
      if (this._user !== user) {
        this._user = user;
        this._loginChangedSubject.next(!!user && !user.expired);
      }
    });

  }

  login() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const documentId = urlParams.get('documentId');
    if (documentId) {
        localStorage.setItem('documentId', documentId);
    }
    console.log('Document id:', documentId);
    return this._userManager.signinRedirect();
  }

  isLoggedIn(): Promise<boolean> {
    return this._userManager.getUser().then(user => {
      const userCurrent = !!user && !user.expired;
      if (this._user !== user) {
        this._loginChangedSubject.next(userCurrent);
      }
      this._user = user;
      return userCurrent;
    });
  }

  completeLogin() {
    return this._userManager.signinRedirectCallback().then(user => {
      console.log("Login complete");
      document.getElementById("info").innerHTML = `<b>&#9432; User: </b>${user.profile["uid"]}<br>${user.profile["firstname"]} ${user.profile["lastname"]}`;
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);
      return user;
    });
  }

  logout() {
    this._userManager.signoutRedirect();
    console.debug("logout");
  }

  completeLogout() {
    this._user = null;
    this._loginChangedSubject.next(false);
    return this._userManager.signoutRedirectCallback();
  }

  getAccessToken() {
    console.log("get token");
    return this._userManager.getUser().then(user => {
      if (!!user && !user.expired) {
        return user.access_token;
      }
      else {
        return null;
      }
    });
  }

}
