import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth-service.component';
import { Router } from '@angular/router';
import { DocumentService } from './core/document.service';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  isLoggedIn = false;

  constructor(private _authService: AuthService,
              private _router: Router,
              private _documentService: DocumentService) {
    this._authService.loginChanged.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    })
  }

  ngOnInit() {
    this._authService.isLoggedIn().then(loggedIn => {
      this.isLoggedIn = loggedIn;
    })
  }

  login() {
    const testToken = environment.testToken;
    const testTokenIsSet = !(!testToken || testToken === null || testToken === "");

    if (!testTokenIsSet || environment.production) {
      this._authService.login();
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const documentId = urlParams.get('documentId');

      this._documentService.getDocument(testToken, documentId);
      this._router.navigate(['/'], {replaceUrl: true});
    }
  }

}
