import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth-service.component';
import { Router } from '@angular/router';
import { DocumentService } from '../core/document.service';


@Component({
  selector: 'app-signin-callback',
  template: '<mat-spinner id="signinCallbackLoading" [diameter]="200"></mat-spinner>'
})

export class SigninRedirectCallbackComponent implements OnInit {
  constructor(private _authService: AuthService,
              private _router: Router,
              private _documentService: DocumentService) { }

  ngOnInit() {
    this._authService.completeLogin().then(user => {
      const documentId = localStorage.getItem('documentId');
      this._documentService.getDocument(user.access_token, documentId);
      this._router.navigate(['/'], { replaceUrl: true });
    })
  }
}
