<nav>
    <!-- this is taken from https://hdi.renault-edh.com/ but with newer logo -->
    <span id="header">
        <img src="/assets/images/RG_RENAULT_GROUP_LOGOTYPE_BLACK_CMYK_v21_1.svg" alt="DGZ" title="DGZ" class="dgz-logo">
        <div class="vl"></div>
        <h1 id="title">Digidoc Zip Service</h1> 
    </span>
    <span id="content">
        <button mat-button (click)="login()" color="primary">Download file</button>
            <div id="info">
                <b>&#9432; Automatic login</b>
                <br>
                Make sure to plug your USB
            </div>
    </span>
    <mat-spinner id="loading" [diameter]="200"></mat-spinner>
</nav>
<div>
    <router-outlet></router-outlet>
</div>